import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from 'components/Layout'
import SectionHeader from 'components/SectionHeader'
import Button from 'components/Button'
import Hero from 'components/Hero'
import Autocomplete from 'components/Autocomplete'
import useForm from 'components/Forms/useFormContact'
import { Box, Flex } from 'rebass'
import {
  FormText,
  FormDropdown,
  FormTextarea,
  FormRadioGroup,
} from 'components/Forms'
import { COUNTRIES_PHONE_PREFIX } from 'constants/index'
import { prefixMask } from 'utils/form-validation'
import { Spacing50 } from 'styles/sharedStyle'

const allItems = COUNTRIES_PHONE_PREFIX.map(
  (prefix) => `${prefix.text} (${prefix.dial_code})`
)

const ServicesBookingContactPage = (props) => {
  const {
    data: {
      page: { hero, formMetaData, village, jsonLdSchema },
    },
    pageContext: { pageLevel },
  } = props

  const { emailRecipient } = village

  const pageData = formMetaData.find(
    (metaData) => metaData.__typename === 'ContentfulLabelServicesContactLab08'
  )
  const {
    enquiryDetailsLabel,
    enquiryDetailsValueALabel,
    enquiryDetailsValueBLabel,
    enquiryDetailsValueCLabel,
    enquiryDetailsErrorMessage,
    servicesLabel,
    servicesErrorMessage,
    bookingReferenceLabel,
    fullNameLabel,
    fullNameErrorMessage,
    mobileNumberLabel,
    mobileNumberPrefixLabel,
    mobileNumberPrefixErrorMessage,
    mobileNumberErrorMessage,
    detailsLabel,
    detailsErrorMessage,
    sendLabel,
    resetLabel,
    introduction,
    titleLabel,
    serviceType,
  } = pageData

  const radioGroupData = [
    {
      id: 'enquiryValueA',
      value: 'enquiryDetailsValueALabel',
      name: enquiryDetailsValueALabel,
    },
    {
      id: 'enquiryValueB',
      value: 'enquiryDetailsValueBLabel',
      name: enquiryDetailsValueBLabel,
    },
    {
      id: 'enquiryValueC',
      value: 'enquiryDetailsValueCLabel',
      name: enquiryDetailsValueCLabel,
    },
  ]

  const sectionHeader =
    formMetaData &&
    formMetaData.find(
      (metaData) => metaData.__typename === 'ContentfulCompSectionHeaderSch01'
    )

  const sectionHeaderProps = sectionHeader
    ? {
        eyebrow: sectionHeader.eyebrow ? sectionHeader.eyebrow : null,
        title: sectionHeader.headline ? sectionHeader.headline : null,
        content: sectionHeader.bodyCopy ? sectionHeader.bodyCopy : null,
        isPageHeader: true,
        withMaxWidth: true,
        background: 'Grey',
      }
    : null

  const media = hero && hero.media && hero.media[0]
  const hasHeroImage =
    (media && media.portrait && media.landscape) ||
    (media &&
      media.videoPlaceholder &&
      media.videoPlaceholder.landscape &&
      media.videoPlaceholder.portrait)
  const heroImage = hasHeroImage
    ? {
        portrait: media.portrait || media.videoPlaceholder.portrait,
        landscape: media.landscape || media.videoPlaceholder.landscape,
        altText:
          media.altText ||
          (media.videoPlaceholder ? media.videoPlaceholder.altText : ''),
      }
    : {}
  const hasHeroVideo = media && media.videoPortrait && media.videoLandscape
  const heroVideo = hasHeroVideo
    ? {
        portrait: media.videoPortrait,
        landscape: media.videoLandscape,
        opacity: media.opacity,
      }
    : {}
  const heroProps = hero
    ? {
        eyebrow: hero.eyebrow,
        content: hero.headline,
        image: heroImage,
        video: heroVideo,
        villageSlug:
          pageLevel === 'collection'
            ? 'The Bicester Collection'
            : village?.name,
      }
    : null

  const initialValues = {
    radioGroup: '',
    servicesLabel: '',
    bookingReferenceLabel: '',
    fullNameLabel: '',
    mobileNumberPrefixLabel: '',
    mobileNumberLabel: '',
    detailsLabel: '',
    cmsData: pageData,
    vilageEmail: emailRecipient,
    enquiryDetailsValueALabel,
    enquiryDetailsValueBLabel,
    enquiryDetailsValueCLabel,
  }

  const {
    handleChange,
    handleSubmit,
    form,
    handleInputChangeOnlyNumber,
    handleInputChangeNoSpecialChar,
    childPrefixCallback,
    handleToggleRadioGroup,
    toggleDropdown,
    toggleReference,
    reset,
    errors,
    onClear,
  } = useForm(initialValues)

  const addDefaultSelectOptin = () => {
    const defaultOption = [{ name: '', value: '' }]
    return [...defaultOption, ...serviceType]
  }

  return (
    <Layout {...props}>
      <Helmet>
        {jsonLdSchema && jsonLdSchema.jsonLdSchema ? (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        ) : null}
      </Helmet>

      {heroProps && <Hero {...heroProps} />}

      <Spacing50 />

      {sectionHeader && <SectionHeader {...sectionHeaderProps} />}

      <Flex justifyContent="center" direction="row" flexWrap="wrap">
        <Box width={800} p={4}>
          <div key={onClear}>
            <form
              method="post"
              onSubmit={handleSubmit}
              autoComplete="on"
              id="contactForm">
              <SectionHeader title={titleLabel} content={introduction} />
              <h5>{enquiryDetailsLabel}</h5>
              <Box>
                <FormRadioGroup
                  label=""
                  name="radioGroup"
                  options={radioGroupData}
                  value={form.radioGroup || ''}
                  handleChange={(e) => handleToggleRadioGroup(e)}
                  hasError={errors.radioGroup}
                  errorMessage={enquiryDetailsErrorMessage}
                />
              </Box>
              {toggleDropdown && (
                <FormDropdown
                  label={servicesLabel}
                  name="servicesLabel"
                  options={addDefaultSelectOptin()}
                  value={form.servicesLabel || ''}
                  handleChange={(e) => handleChange(e)}
                  errorMessage={servicesErrorMessage}
                  hasError={errors.servicesLabel}
                />
              )}

              {toggleReference && (
                <FormText
                  label={bookingReferenceLabel}
                  name="bookingReferenceLabel"
                  value={form.bookingReferenceLabel || ''}
                  handleChange={(e) => handleInputChangeNoSpecialChar(e)}
                  errorMessage={enquiryDetailsErrorMessage}
                />
              )}

              <FormText
                label={fullNameLabel}
                name="fullNameLabel"
                value={form.fullNameLabel || ''}
                handleChange={(e) => handleChange(e)}
                errorMessage={fullNameErrorMessage}
                hasError={errors.fullNameLabel}
              />

              <Flex direction="row" flexWrap="wrap" mx={-2}>
                <Box flex="1 1 auto" pt={0} px={1}>
                  <Autocomplete
                    suggestions={allItems}
                    parentCallback={(e) => childPrefixCallback(e)}
                    name="mobileNumberPrefixLabel"
                    label={mobileNumberPrefixLabel}
                    hasError={errors.mobileNumberPrefixLabel}
                    errorMessage={mobileNumberPrefixErrorMessage}
                    onClear={onClear}
                    autoComplete="off"
                    mask={prefixMask}
                  />
                </Box>
                <Box flex="1 1 auto" px={1} order={2} width={[1 / 2]}>
                  <FormText
                    label={mobileNumberLabel}
                    name="mobileNumberLabel"
                    value={form.mobileNumberLabel || ''}
                    handleChange={(e) => handleInputChangeOnlyNumber(e)}
                    errorMessage={mobileNumberErrorMessage}
                    hasError={errors.mobileNumberLabel}
                  />
                </Box>
              </Flex>

              <FormTextarea
                label={detailsLabel}
                name="detailsLabel"
                value={form.detailsLabel}
                handleChange={(e) => handleChange(e)}
                rows="5"
                cols="20"
                errorMessage={detailsErrorMessage}
                hasError={errors.detailsLabel}
              />

              <Flex direction="row" mx={-2} fontSize={[3, 4]} flexWrap="wrap">
                <Box px={[1, 2, 3, 4]}>
                  <Button type="submit" name="send">
                    {' '}
                    {sendLabel}
                  </Button>{' '}
                </Box>
                <Box px={[0, 2, 3, 4]} width={1 / 4}>
                  <Button name="reset" onClick={reset}>
                    {' '}
                    {resetLabel}{' '}
                  </Button>
                </Box>
              </Flex>
            </form>
          </div>
        </Box>
      </Flex>
    </Layout>
  )
}

ServicesBookingContactPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageContext: PropTypes.shape({
    pageLevel: PropTypes.string.isRequired,
  }).isRequired,
}

export const ContactServicesBookingQuery = graphql`
  query(
    $id: String!
    $openingHoursId: String!
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
    $pageTypeSlug: String
    $villageSlug: String
  ) {
    page: contentfulPageTemplateFormsT10(id: { eq: $id }) {
      jsonLdSchema {
        jsonLdSchema
      }
      id
      hero {
        eyebrow
        headline
        ...heroMediaQuery
      }
      locale: node_locale
      formMetaData {
        __typename
        ... on Node {
          ... on ContentfulLabelServicesContactLab08 {
            enquiryDetailsLabel
            enquiryDetailsValueALabel
            enquiryDetailsValueBLabel
            enquiryDetailsValueCLabel
            enquiryDetailsErrorMessage
            servicesLabel
            servicesErrorMessage
            bookingReferenceLabel
            fullNameLabel
            fullNameErrorMessage
            mobileNumberPrefixLabel
            mobileNumberPrefixErrorMessage
            mobileNumberLabel
            mobileNumberErrorMessage
            detailsLabel
            detailsErrorMessage
            sendLabel
            resetLabel
            emailRecipient
            introduction
            titleLabel
            serviceType {
              value: formOptionValue
              name: formOptionLabel
            }
          }
          ... on ContentfulCompSectionHeaderSch01 {
            headline
            eyebrow
            bodyCopy {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      village {
        name
        code
        currency
        emailRecipient
        villageSlug: slug
        servicesHeader {
          ...header
        }
        home: page_template_home_t01 {
          footer {
            ...footer
          }
          locale: node_locale
        }
        openingHours {
          ...villageOpeningHours
        }
        defaultLocale
        openingStatusLabel
      }
      pageTitle
      pageDescription
      hideFromSearchEngine
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          ...contentfulOpeningHoursExceptions
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }
    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }
    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
  }
`

export default ServicesBookingContactPage
