import { useState } from 'react'
import { navigate } from 'gatsby'

const useFormContact = (initialValues) => {
  const [form, setForm] = useState({ ...initialValues })
  const [errors, setErrors] = useState({})
  const [toggleDropdown, setToggleDropdown] = useState(false)
  const [toggleReference, setToggleReference] = useState(false)
  const [onClear, setOnClear] = useState(false)
  const [radioLabel, setRadioLabel] = useState('')

  const {
    fullNameLabel,
    mobileNumberLabel,
    servicesLabel,
    bookingReferenceLabel,
    detailsLabel,
  } = initialValues.cmsData

  const {
    vilageEmail,
    enquiryDetailsValueALabel,
    enquiryDetailsValueBLabel,
    enquiryDetailsValueCLabel,
  } = initialValues

  const handleChange = (event) => {
    event.persist()
    setForm((input) => ({
      ...input,
      [event.target.name]: event.target.value,
    }))
    setErrors((input) => ({
      ...input,
      [event.target.name]: false,
    }))
  }

  const handleDateChange = (value, name) => {
    setForm(() => ({
      ...form,
      [name]: {
        ...form[name],
        value,
      },
    }))
  }

  const handleUpdate = (obj) => {
    setForm(() => obj)
  }

  const handlePageChange = (event) => {
    handleChange(event)

    const currentSelectedOption = form[event.target.name].options.filter(
      (option) => option.value === event.target.value
    )[0]

    if (currentSelectedOption.value) {
      navigate(currentSelectedOption.value)
    }
  }

  const handleCheckbox = (event, name = event.target.name) => {
    event.persist()
    setForm(() => ({
      ...form,
      [name]: {
        ...form[name],
        value: event.target.checked,
      },
    }))
  }

  const handleDecrement = (event, name, quantity) => {
    event.persist()

    const newObj = Object.assign({}, form.quantity.options)
    const arrayKeys = Object.keys(newObj)

    arrayKeys.forEach((key) => {
      if (newObj[key].name === name) {
        if (quantity > 0) newObj[key].value = quantity - 1
      }
    })

    setForm(() => ({
      ...form,
    }))
  }

  const handleIncrement = (event, name, quantity) => {
    event.persist()

    const newObj = Object.assign({}, form.quantity.options)
    const arrayKeys = Object.keys(newObj)

    arrayKeys.forEach((key) => {
      if (newObj[key].name === name) {
        if (quantity < 10) newObj[key].value = quantity + 1
      }
    })

    setForm(() => ({
      ...form,
    }))
  }

  const handleInputChangeOnlyNumber = (event) => {
    event.persist()
    const regex = /^[0-9\b]+$/
    if (event.target.value === '' || regex.test(event.target.value)) {
      setForm((input) => ({
        ...input,
        [event.target.name]: event.target.value,
      }))

      setErrors((input) => ({
        ...input,
        [event.target.name]: false,
      }))
    }
  }

  const handleInputChangeNoSpecialChar = (event) => {
    event.persist()
    setForm((input) => ({
      ...input,
      [event.target.name]: event.target.value.replace(/[^\w\s]/gi, ''),
    }))
  }

  const childPrefixCallback = (prefix) => {
    setForm((input) => ({ ...input, mobileNumberPrefixLabel: prefix }))
    setErrors((input) => ({
      ...input,
      mobileNumberPrefixLabel: false,
    }))
  }

  const handleToggleDefault = (event) => {
    event.persist()
    setToggleReference(false)
    setToggleDropdown(false)
    handleChange(event)
  }

  const handleToggleDropdown = (event) => {
    event.persist()
    setToggleDropdown(true)
    setToggleReference(true)
    handleChange(event)
  }

  const handleToggleReference = (event) => {
    event.persist()
    setToggleDropdown(true)
    setToggleReference(false)
    handleChange(event)
  }

  const handleToggleRadioGroup = (event) => {
    switch (event.target.value) {
      case 'enquiryDetailsValueALabel':
        handleToggleDropdown(event)
        setRadioLabel(enquiryDetailsValueALabel)
        break
      case 'enquiryDetailsValueBLabel':
        handleToggleReference(event)
        setRadioLabel(enquiryDetailsValueBLabel)
        break
      case 'enquiryDetailsValueCLabel':
        handleToggleDefault(event)
        setRadioLabel(enquiryDetailsValueCLabel)
        break
      default:
    }
  }

  const reset = () => {
    setForm(initialValues)
    setErrors({})
    setOnClear(!onClear)
  }

  const isNullOrWhitespace = (input) => {
    if (typeof input === 'undefined' || input == null) return true
    return input.replace(/\s/g, '').length < 1
  }

  const validateForm = (formValues) => {
    const allErrors = {}
    let formIsValid = true

    if (!formValues.radioGroup) {
      allErrors.radioGroup = true
      formIsValid = false
    }
    if (toggleDropdown) {
      if (!formValues.servicesLabel) {
        allErrors.servicesLabel = true
        formIsValid = false
      }
    }

    if (
      !formValues.fullNameLabel ||
      isNullOrWhitespace(formValues.fullNameLabel)
    ) {
      allErrors.fullNameLabel = true
      formIsValid = false
    }

    if (!formValues.mobileNumberPrefixLabel) {
      allErrors.mobileNumberPrefixLabel = true
      formIsValid = false
    }

    if (!formValues.mobileNumberLabel) {
      allErrors.mobileNumberLabel = true
      formIsValid = false
    }

    if (
      !formValues.detailsLabel ||
      isNullOrWhitespace(formValues.detailsLabel)
    ) {
      allErrors.detailsLabel = true
      formIsValid = false
    }
    setErrors(allErrors)
    return formIsValid
  }

  const handleBlur = () => {
    const validateErrors = validateForm(form)
    setErrors(validateErrors)
  }

  const sendEmail = () => {
    const newLine = '%0A'
    const service = toggleDropdown
      ? `${servicesLabel}${newLine}${form.servicesLabel}${newLine}`
      : ''

    const referenceValues =
      form.bookingReferenceLabel === undefined ? '' : form.bookingReferenceLabel

    const reference = toggleReference
      ? `${bookingReferenceLabel}${newLine}${referenceValues}${newLine}`
      : ''

    const mailContent =
      `mailto:${vilageEmail}?subject=${radioLabel}` +
      `&body=${service}${reference}${fullNameLabel}${newLine}${form.fullNameLabel}` +
      `${newLine}${mobileNumberLabel}${newLine}${form.mobileNumberPrefixLabel}` +
      `${form.mobileNumberLabel}${newLine}${detailsLabel}${newLine}${form.detailsLabel}${newLine}`
    window.location.href = mailContent.trim()
  }

  const handleSubmit = (event) => {
    if (event) event.preventDefault()
    if (validateForm(form)) {
      sendEmail()
    }
  }

  return {
    handleChange,
    handlePageChange,
    handleUpdate,
    handleCheckbox,
    handleDateChange,
    handleSubmit,
    handleDecrement,
    handleIncrement,
    form,
    handleInputChangeOnlyNumber,
    handleInputChangeNoSpecialChar,
    childPrefixCallback,
    handleToggleRadioGroup,
    toggleDropdown,
    toggleReference,
    reset,
    handleBlur,
    errors,
    sendEmail,
    onClear,
  }
}

export default useFormContact
